import axios from "axios";
import httpClient from "./httpClient";
const BASE_URL = "https://br-crawler.septemsystems.com";
// const BASE_URL = "http://localhost:8086";
const SAVE = "save";

const getArtistsFromDiscogs = (page, limit) =>
  axios.get(
    `${BASE_URL}/discogs/get_all_artist?page=${page - 1}&limit=${limit}`
  );

const getArtistsByTermSearchFromDiscogs = (term) =>
  httpClient.get(`/${SAVE}/list?searchQuery=${term}`);

const getArtistDetailsFromDiscogsById = (discogsId) =>
  httpClient.get(`/${SAVE}/scrape-discog-artist/${discogsId}`);

// axios.get(
//   `http://localhost:3300/api/get_all_artist?page=${page - 1}&limit=${limit}`
// );

export {
  getArtistsFromDiscogs,
  getArtistsByTermSearchFromDiscogs,
  getArtistDetailsFromDiscogsById,
};
