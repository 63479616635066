<template>
  <CardToolbar
    v-loading.fullscreen.lock="loading"
    class="crawled-artist-details"
    description="Details"
    title="Artist Info"
    :show-toolbar-buttons="Object.keys(artistInfo).length > 0"
  >
    <template v-slot:toolbar>
      <button
        v-if="$route.query.uuid && $route.query.uuid !== 'null'"
        :disabled="isAssociated"
        class="btn btn-sm btn-primary px-4 mx-2"
        @click="linkArtistToSoundChart"
      >
        Associate
      </button>
      <span v-else />
      <!--      <button-->
      <!--        v-if="$route.name !== 'crawler'"-->
      <!--        :disabled="inSearchHistory"-->
      <!--        class="btn btn-sm btn-primary px-4"-->
      <!--        @click="addArtistToSearchHistory()"-->
      <!--      >-->
      <!--        {{ inSearchHistory ? "Saved" : "Save" }}-->
      <!--      </button>-->
      <!--      <button v-else disabled class="btn btn-sm btn-light px-4">Saved</button>-->
    </template>
    <template v-slot:body>
      <div v-if="Object.keys(artistInfo).length > 0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <img
              :src="
                artistInfo.avatar
                  ? artistInfo.avatar
                  : '/media/avatars/blank.png'
              "
              alt="Artist Avatar"
              class="avatar"
            />
          </div>
          <div class="col-10">
            <h3>
              {{
                artistInfo.realName ? artistInfo.realName : $route.query.search
              }}
            </h3>
          </div>
        </div>
        <div class="row mt-2 wrapper-artist">
          <div class="col-10 offset-2">
            <div class="row">
              <div class="col-3">
                <b>Profile:</b>
              </div>
              <div class="col-9">
                {{ artistInfo.profile ? artistInfo.profile : "" }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b>Total Releases:</b>
              </div>
              <div class="col-9">
                {{ artistInfo.releases ? artistInfo.releases : "" }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b>No. of Recent Releases:</b>
              </div>
              <div class="col-9">
                {{
                  artistInfo.noOfRecentReleases
                    ? artistInfo.noOfRecentReleases
                    : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b>Sites:</b>
              </div>
              <div class="col-9">
                <a
                  v-for="(site, index) in artistInfo.discogArtistSites"
                  :key="index"
                  :href="site.url"
                  target="_blank"
                  style="margin-right: 5px"
                  >{{
                    site.sites && site.sites.nameOnDiscog
                      ? site.sites.nameOnDiscog
                      : ""
                  }},
                </a>
              </div>
            </div>
            <div
              v-for="(link, index) in artistInfo.discogArtistReleasesTables"
              :key="index"
            >
              <div class="row mt-5">
                <h3>Release No.{{ index + 1 }}</h3>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>Lable:</b>
                </div>
                <div class="col-9">
                  <a
                    target="_blank"
                    :href="link.labelUrl"
                    style="margin-right: 5px"
                    >{{ link.label ? link.label : "" }}</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>Format:</b>
                </div>
                <div class="col-9">
                  <a
                    target="_blank"
                    :href="link.formatUrl"
                    style="margin-right: 5px"
                    >{{ link.format ? link.format : "" }}</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>Country:</b>
                </div>
                <div class="col-9">
                  <a
                    target="_blank"
                    :href="link.countryUrl"
                    style="margin-right: 5px"
                    >{{ link.country ? link.country : "" }}</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>Released:</b>
                </div>
                <div class="col-9">
                  <a target="_blank" style="margin-right: 5px">{{
                    link.released ? link.released : ""
                  }}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <b>Genre:</b>
                </div>
                <div class="col-9">
                  <a
                    target="_blank"
                    :href="link.genreUrl"
                    style="margin-right: 5px"
                    >{{ link.genre ? link.genre : "" }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CardToolbar>
</template>

<script>
import CardToolbar from "../../common/components/CardToolbar";
import {
  addDiscogsSearchHistory,
  associateDiscogsSearchHistory,
} from "../../api/user.api";
import { ElNotification } from "element-plus";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  name: "ArtistsDetailsView",
  components: { CardToolbar },
  props: {
    artistInfo: {
      type: Object,
      default: () => {
        //
      },
    },
  },
  setup(props, context) {
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const isAssociated = ref(props.artistInfo.associationExists);
    // const inSearchHistory = ref(false);
    // const isAssociatedToSoundChart = ref(false);

    // const discogsSearchHistory = computed(
    //   () => store.getters["UserModule/getDiscogsSearchHistory"]
    // );

    // const artistSavedToHistory = computed(() => {
    //   const result = discogsSearchHistory.value.find((object) => {
    //     return object.discog_id === props.artistInfo.discog_id;
    //   });
    //   return !!result;
    // });
    //
    // const artistAssociatedToSoundChart = computed(() => {
    //   const result = discogsSearchHistory.value.find((object) => {
    //     return object.discog_id === props.artistInfo.discog_id;
    //   });
    //   return !!result;
    // });

    const linkArtistToSoundChart = async () => {
      await associateArtistToSearchHistory(route.query.uuid);
    };

    const addArtistToSearchHistory = async () => {
      loading.value = true;
      try {
        await addDiscogsSearchHistory(props.artistInfo.discogId);
        await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
        localStorage.setItem(
          "fetch-discogs-search-history",
          Math.random() + "discogs-search-history"
        );
        ElNotification({
          title: "Saved!",
          message: "User added to search history",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error!",
          message: "Error adding to search history!",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const associateArtistToSearchHistory = async (uuid) => {
      loading.value = true;
      try {
        await associateDiscogsSearchHistory({
          discogId: props.artistInfo.discogId,
          uuid: uuid,
        });
        await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
        localStorage.setItem(
          "fetch-discogs-search-history",
          Math.random() + "discogs-search-history"
        );
        isAssociated.value = true;
        ElNotification({
          title: "Saved!",
          message: "User associate to search history",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error!",
          message: "Error associate to search history!",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    watch(
      () => props.artistInfo,
      (artistInfo) => {
        isAssociated.value = artistInfo.associationExists;
      },
      { immediate: true, deep: true }
    );
    // watch(
    //   () => props.artistInfo,
    //   (artistInfo) => {
    //     const result = discogsSearchHistory.value.find((object) => {
    //       return object.discogsId && object.discogsId === artistInfo.discogId;
    //     });
    //     if (result) {
    //       inSearchHistory.value = true;
    //       isAssociatedToSoundChart.value = !!result.artist;
    //     } else {
    //       inSearchHistory.value = false;
    //       isAssociatedToSoundChart.value = false;
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );

    // watch(
    //   () => discogsSearchHistory.value,
    //   (value) => {
    //     const result = value.find((object) => {
    //       return (
    //         object.discogsId && object.discogsId === props.artistInfo.discogId
    //       );
    //     });
    //     if (result) {
    //       inSearchHistory.value = true;
    //       isAssociatedToSoundChart.value = !!result.artist;
    //     } else {
    //       inSearchHistory.value = false;
    //       isAssociatedToSoundChart.value = false;
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );

    return {
      loading,
      isAssociated,
      // inSearchHistory,
      //isAssociatedToSoundChart,
      addArtistToSearchHistory,
      linkArtistToSoundChart,
      associateArtistToSearchHistory,
    };
  },
};
</script>

<style scoped>
.crawled-artist-details {
  height: 98%;
  overflow-y: scroll;
}
.avatar {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  margin-left: 10px;
}
.headInfo {
  display: inline;
  float: left;
  width: 100px;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.cntnt {
  margin-left: 100px;
  font-size: 14px;
  word-wrap: break-word;
}
</style>
