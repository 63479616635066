<template>
  <div class="row" v-loading.fullscreen.lock="loading">
    <div class="col-4" style="height: 85vh">
      <CrawledArtists :artists="artists" @getArtistDetails="getArtistDetails" />
      <!--      <Pagination-->
      <!--        :total="total"-->
      <!--        v-model:page="listQuery.page"-->
      <!--        v-model:limit="listQuery.limit"-->
      <!--        @pagination="getList"-->
      <!--      />-->
    </div>
    <div class="col-8" style="height: 85vh">
      <ArtistsDetailsView :artistInfo="artistInfo" />
    </div>
  </div>
</template>

<script>
import { getArtistsByTermSearchFromDiscogs } from "@/api/crawlArtistInfo";
import { getArtistDetailsFromDiscogsById } from "../../api/crawlArtistInfo";
import { ElNotification } from "element-plus";
import CrawledArtists from "../components/CrawledArtists";
import ArtistsDetailsView from "../components/ArtistsDetailsView";
export default {
  name: "Crawler",
  components: {
    ArtistsDetailsView,
    CrawledArtists,
    // Pagination,
  },
  data() {
    return {
      artists: [],
      loading: false,
      total: 1,
      listQuery: {
        page: 1,
        limit: 5,
      },
      artistInfo: {},
    };
  },
  computed: {
    searchQuery() {
      return this.$route.query.search;
    },
  },
  watch: {
    searchQuery: {
      async handler(value) {
        if (value) {
          this.loading = true;
          await this.getArtistsFromDiscogsByTermSearch();
          this.loading = false;
        } else {
          this.artistInfo = {};
          this.artists = [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getArtistDetails(id) {
      this.loading = true;
      try {
        this.artistInfo = {};
        const { data } = await getArtistDetailsFromDiscogsById(id);
        this.artistInfo = data;
      } catch {
        ElNotification({
          title: "Error",
          message: "Error getting data",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async getArtistsFromDiscogsByTermSearch() {
      this.loading = true;
      try {
        this.artistInfo = {};
        const { data } = await getArtistsByTermSearchFromDiscogs(
          this.searchQuery
        );
        this.artists = data;
      } catch {
        ElNotification({
          title: "Error",
          message: "Error getting data",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.artists = [];
    // if (this.searchQuery) {
    //   await this.getArtistsFromDiscogsByTermSearch();
    // }
    this.loading = true;
    await this.$store.dispatch("UserModule/fetchDiscogsSearchHistory");
    this.loading = false;

    // this.total = 0;
    // this.loading = true;
    // const {
    //   data: { data },
    // } = await getArtistsFromDiscogs(1, 5);
    // this.artists = data.all;
    // this.total = data.count;
    // this.loading = false;
  },
};
</script>

<style scoped>
/*.kt_content {*/
/*  margin-top: 6em;*/
/*  padding-left: 0 !important;*/
/*  padding-right: 0 !important;*/
/*}*/

/*.bg {*/
/*  background: white;*/
/*  padding: 15px 5px 15px 5px;*/
/*}*/
</style>
