<template>
  <CardToolbar
    class="crawled-artists"
    description="Search results"
    title="Crawler Results"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <table class="table table-responsive table-row-gray-300 gy-3">
        <tbody>
          <tr v-for="(item, index) in artists" :key="index">
            <td class="table-item">
              <div class="d-flex align-items-center">
                <el-avatar
                  class="cursor-pointer"
                  @click="$emit('getArtistDetails', item.discogsId)"
                  :size="35"
                  :src="item.avatar ? item.avatar : '/media/avatars/blank.png'"
                  alt="Artist Avatar"
                ></el-avatar>
                <div
                  @click="$emit('getArtistDetails', item.discogsId)"
                  class="m-3 cursor-pointer"
                >
                  {{ item.name }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
export default {
  name: "CrawledArtists",
  components: { CardToolbar },
  data() {
    return {};
  },
  props: {
    artists: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.crawled-artists {
  height: 98%;
  overflow-y: scroll;
}
body {
  background-color: #eee;
}
#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: white;
}
.sidebar-header {
  padding: 10px 5px 10px 15px;
}
.avatar {
  //max-width: 100%;
  //max-height: 100%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  margin-left: 10px;
}
.wdt {
  width: 90%;
  margin: auto;
}
.stl {
  padding: 5px 5px;
  //border-bottom: 1px solid #6d6d80;
  border-top: 1px solid #888;
  //margin-bottom: 30px;
}
//.wrapper-crawler {
//  height: 300px;
//  overflow: scroll;
//}
.song-name {
  margin: 5px;
}
</style>
